export default class PhoneForm {
  constructor() {
    $(document).ready(function() {
      $("#form-phones").on("cocoon:after-insert", function(e) {
        disableAddPhoneButton();
        showAdditionalPhoneFields();
      });

      $("#form-phones").on("cocoon:after-remove", function(e) {
        disableAddPhoneButton();
      });

      showAdditionalPhoneFields();
    });

    function disableAddPhoneButton() {
      if ($('.person-phone-fields').length == 4) {
        $('.add-phone').hide();
      } else {
        $('.add-phone').show();
      }
    }

    function showAdditionalPhoneFields() {
      $(".contact-person-check").on("change", function() {
        renderAdditionalPhoneFields($(this));
      })

      $(".contact-relation").on("change", function() {
        renderRelationDiv($(this));
      });

      $(".contact-person-check").each(function() {
        renderAdditionalPhoneFields($(this));
      })

      $(".contact-relation").each(function() {
        renderRelationDiv($(this));
      })
    }

    function renderAdditionalPhoneFields(contactPerson) {
      let phoneForm             = $(contactPerson).parent().parent().parent().parent().parent()
      let additionalPhoneFields = phoneForm.find(".additional-phone-field").show()

      if ($(contactPerson).is(":checked")) {
        additionalPhoneFields.show();
      } else {
        additionalPhoneFields.hide();
      }
    }

    function renderRelationDiv(relationInput) {
      let phoneForm = $(relationInput).parent().parent().parent().parent().parent()
      let relation  = $("option:selected", relationInput).text();

      if (relation == "Sonstige") {
        $(phoneForm).find(".contact-relation-div").show();
      } else {
        $(phoneForm).find(".contact-relation-div").hide();
        $(phoneForm).find(".contact-relation-div").find("input").val("");
      }
    }
  }
}
