export default class CoursesForm {
  constructor() {
    $(document).ready(function() {
      showProjectCourseDetails();
      showChildcareDetailsInput();
      showCourseAddress();

      $("#course_project_id").on("change", function(e){
        showProjectCourseDetails();
        resetProjectCourseDetails();
      })

      $("#hasChildcareYes").on("change", function() {
        showChildcareDetailsInput();
      })

      $("#hasChildcareNo").on("change", function() {
        showChildcareDetailsInput();
      })

      function showCourseAddress() {
        let courseAddress = $("#course_course_address").val();
        if (courseAddress && courseAddress.length < 1) { showCourseAddressFromProject() }

        $("#course_project_id").on("change", function(e) {
          e.preventDefault();
          showCourseAddressFromProject()
        })
      }

      function showCourseAddressFromProject() {
        let courseAddressFromProject = $("#course_project_id").find('option:selected').data('course_address');

        if (courseAddressFromProject && courseAddressFromProject.length > 0) {
          $("#course_course_address").val(courseAddressFromProject);
        }
      }

      function showProjectCourseDetails() {
        $('#course_target_audience_id option').hide();
        $('#course_course_level_id option').hide();

        let projectId   = $("#course_project_id").val();
        let projectData = "#project-" + projectId

        if ($(projectData).length > 0) {
          let courseLevels = $(projectData).data('course-levels');
          let audiences    = $(projectData).data('audiences');
          courseLevels     = courseLevels.length > 1 ? courseLevels.split(", ") : [courseLevels.toString()];
          audiences        = audiences.length > 1 ? audiences.split(", ") : [audiences.toString()];

          $(courseLevels).each(function(index, level) {
            $('#course_course_level_id option[value="'+ level + '"]').show();
            $('.course-level-select').show();
            $('#course_course_level_id').prop('required', true);
          })

          $(audiences).each(function(index, level) {
            $('#course_target_audience_id option[value="'+ level + '"]').show();
            $('.target-audience-select').show();
            $('#course_target_audience_id').prop('required', true);
          })
        } else {
          $('.course-level-select').hide();
          $('.target-audience-select').hide();
          $('#course_course_level_id').prop('required', false);
          $('#course_target_audience_id').prop('required', false);
        }
      }

      function resetProjectCourseDetails() {
        $("#course_course_level_id").prop("selectedIndex", 0);
        $("#course_target_audience_id").prop("selectedIndex", 0);
      }

      function showChildcareDetailsInput() {
        if($("#hasChildcareYes").is(":checked")) {
          $(".childcare-details").show();
        } else {
          $(".childcare-details").hide();
        }

        if($("#hasChildcareNo").is(":checked")) {
          $(".childcare-details").hide();
        } else {
          $(".childcare-details").show();
        }

        if (!$("#hasChildcareYes").is(":checked") && !$("#hasChildcareNo").is(":checked")) {
          $(".childcare-details").hide();
        }
      }
    })
  }
}
