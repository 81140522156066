import updateProjectOptions from "./update_project_options";
export default class SearchCourse {
  constructor() {
    $(document).ready(function () {
      updateProjectOptions();
      showChildrenAgesForm();
      incrementChildren();

      $(".add-children-form .dropdown-menu").on("click", function(e) {
        e.stopPropagation();
      })
    })

    $(document).on("turbo:load", function() {
      updateProjectOptions();
      showChildrenAgesForm();
      incrementChildren();

      $(".add-children-form .dropdown-menu").on("click", function(e) {
        e.stopPropagation();
      })
    })

    function incrementChildren() {
      $('.quantity-children-right-plus').on("click", function (e) {
        e.preventDefault();

        let quantityChildren = parseInt($('#quantity-children').val());
        $('#quantity-children').val(quantityChildren + 1);
        incrementChildAgeForm();
      });

      $('.quantity-children-left-minus').on("click", function (e) {
        e.preventDefault();

        let quantityChildren = parseInt($('#quantity-children').val());
        if (quantityChildren > 0) {
          $('#quantity-children').val(quantityChildren - 1);

          $(".children-ages-form .child-age-form").last().remove();
        }
      });

      $("#quantity-children").on("change", function(e) {
        e.preventDefault();

        let quantityChildren = parseInt($('#quantity-children').val());
        $('#quantity-children').val(quantityChildren);
        $(".children-ages-form .child-age-form").remove();

        for(var i = 0; i < quantityChildren; i++){
          incrementChildAgeForm();
        }
      })
    }

    function incrementChildAgeForm() {
      $(".children-ages-form").append($(".hidden-children-ages-form .child-age-form").clone());
      
      let children      = $(".children-ages-form .child-age-form");
      let childrenCount = children.length;
      let last_child    = children.last();
      let input         = last_child.find("input");

      last_child.find("span.num-of-child").text(childrenCount);
      input.attr("name", `ages[${childrenCount - 1}]`)
    }

    function showChildrenAgesForm() {
      if ($("#ages").length > 0) {
        let ages = $("#ages").data('ages').toString().split(",");
        $(ages).each(function(i, age) {
          $(".children-ages-form").append($(".hidden-children-ages-form .child-age-form").clone());
      
          let children      = $(".children-ages-form .child-age-form");
          let childrenCount = children.length;
          let last_child    = children.last();
          let input         = last_child.find("input");

          last_child.find("span.num-of-child").text(childrenCount);
          input.attr("name", `ages[${childrenCount - 1}]`)
          input.val(age)
        })
      }
    }
  }
}