import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="person_course"
export default class extends Controller {
  showCancelPerson() {
    if($(".cancel-person-course-wrapper").is(":visible")) {
      $(".cancel-person-course-wrapper").hide();
    } else {
      $(".cancel-person-course-wrapper").show();
    }
  }

  showChangePerson() {
    if($(".change-person-course-wrapper").is(":visible")) {
      $(".change-person-course-wrapper").hide();
    } else {
      $(".change-person-course-wrapper").show();
    }
  }

  showCourseEnding() {
    if($(".add-course-ending-wrapper").is(":visible")) {
      $(".add-course-ending-wrapper").hide();
    } else {
      $(".add-course-ending-wrapper").show();
    }
  }

  showCourseRepetition() {
    if($(".add-course-repetition-wrapper").is(":visible")) {
      $(".add-course-repetition-wrapper").hide();
    } else {
      $(".add-course-repetition-wrapper").show();
    }
  }

  showCourseEndings() {
    if($(".add-course-endings-wrapper").is(":visible")) {
      $(".add-course-endings-wrapper").hide();
    } else {
      $(".add-course-endings-wrapper").show();
    }
  }

  showCourseStarts() {
    if($(".edit-course-starts-wrapper").is(":visible")) {
      $(".edit-course-starts-wrapper").hide();
    } else {
      $(".edit-course-starts-wrapper").show();
    }
  }

  hideCourseEndings() {
    $(".add-course-endings-wrapper").hide();
  }

  hideCourseStarts() {
    $(".edit-course-starts-wrapper").hide();
  }

  showSendSms() {
    if($(".send-sms-wrapper").is(":visible")) {
      $(".send-sms-wrapper").hide();
    } else {
      $(".send-sms-wrapper").show();
    }
  }

  hideSendSms() {
    $(".send-sms-wrapper").hide();
  }

  checkAllPeople() {
    $(".check-all-people").hide();
    $(".uncheck-all-people").show();
    $(".add-course-endings").show();
    $(".edit-course-starts").show();
    $(".print-pdf").css('display', 'contents');
    $(".send-sms").show();
    $(".mark_course_end").prop('checked', true);
  }

  uncheckAllPeople() {
    $(".uncheck-all-people").hide();
    $(".check-all-people").show();
    $(".add-course-endings").hide();
    $(".edit-course-starts").hide();
    $(".print-pdf").hide();
    $(".send-sms").hide();
    $(".mark_course_end").prop('checked', false);
    $(".selected-assigned-people-info").hide();
  }

  showUnitsPresentField(e) {
    let input = $(e.target.parentElement.parentElement.getElementsByTagName("input"));
    let text  = $(e.target.parentElement.nextElementSibling);

    if ($(input).is(':visible')) {
      $(input).hide();
      $(text).show();
    } else {
      $(input).show();
      $(text).hide();
    }
  }

  updateUnitsPresent(e) {
    const csrfToken    = document.querySelector("[name='csrf-token']").content
    let courseId       = e.target.dataset.courseId;
    let personCourseId = e.target.dataset.personCourseId;
    let unitsPresent   = e.target.value;

    fetch(`/courses/${courseId}/person_courses/${personCourseId}/update_units_present`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ units_present: unitsPresent })
    })
      .then(response => response.json())
      .then(data => {
        let wrapper = $(`#units-present-${data.id}`);
        $(wrapper).find('input').hide();
        $(wrapper).find('span').text(`${data.units_in_percent}%`)
        $(wrapper).find('span').show();
      })
  }

  resetCourseEndingsForm() {
    $(".alert").remove();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    $(".add-course-endings").on("click", function() {
      $('.select-two').select2();
      if($(".add-course-endings-wrapper").is(":visible")) {
        $(".add-course-endings-wrapper").hide();
      } else {
        $(".add-course-endings-wrapper").show();
      }
    })

    $(".uncheck-all-people").hide();
    $(".check-all-people").show();
    $(".add-course-endings").hide();
    $(".edit-course-starts").hide();
    $(".send-sms").hide();
    $(".mark_course_end").prop('checked', false);
    $(".selected-assigned-people-info").hide();
  }

  resetSmsForm() {
    $(".alert").remove();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    $(".uncheck-all-people").hide();
    $(".check-all-people").show();
    $(".add-course-endings").hide();
    $(".edit-course-starts").hide();
    $(".send-sms").hide();
    $(".mark_course_end").prop('checked', false);
    $(".selected-assigned-people-info").hide();
  }

  resetEditCourseStarts() {
    $(".alert").remove();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    $(".edit-course-starts").on("click", function() {
      $('.select-two').select2();
      if($(".edit-course-starts-wrapper").is(":visible")) {
        $(".edit-course-starts-wrapper").hide();
      } else {
        $(".edit-course-starts-wrapper").show();
      }
    })

    $(".uncheck-all-people").hide();
    $(".check-all-people").show();
    $(".add-course-endings").hide();
    $(".edit-course-starts").hide();
    $(".send-sms").hide();
    $(".mark_course_end").prop('checked', false);
    $(".selected-assigned-people-info").hide();
  }
}
