export default class ReportForm {
  constructor() {
    $(document).ready(function() {
      $("#form-reports").on("cocoon:after-insert", function(e) {
        $('.select-two').select2();
      });

      $("#form-reports").on("cocoon:after-remove", function(e) {
        $('.select-two').select2();
      });
    });
  }
}
