import DATATABLE_TRANSLATIONS from "./datatable_translations";

export default class CoursesDataTable {
  constructor() {
    $(document).ready(function() {
      showCoursesTable();
    });

    $(document).on("turbo:render", function() {
      showCoursesTable();
    });

    function showCoursesTable() {
      $('#courses-datatable').dataTable({
        "order": [],
        "columnDefs": [ {
          "targets"  : 'no-sort',
          "orderable": false,
        }],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#courses-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "course_number"},
          {"data": "provider"},
          {"data": "actions"}
        ]
        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });
    }
  }
}
