import 'moment/locale/de';
import { German } from "flatpickr/dist/l10n/de.js"

export default class CustomDatepicker {
  constructor() {
    formatDatePicker();

    $("#tab-form-certifications").on("cocoon:after-insert", function() {
      formatDatePicker();
    });

    $("#tab-form-children").on("cocoon:after-insert", function() {
      formatDatePicker();
      $(".tags-input").tagsinput('refresh');
    });

    $("#tab-form-exams").on("cocoon:after-insert", function() {
      formatDatePicker();
    });

    $(".year-input").datepicker({
      format: "yyyy",
      viewMode: "years",
      minViewMode: "years"
    });

    function formatDatePicker() {
      flatpickr(".flatpickr-input", {
        "locale": German,
        "dateFormat": "d.m.Y",
        "allowInput": true
      });
    }
  }
}
