function updateChildcareOptions() {
  let courseStart = $("#course_course_start").val()
  let courseEnd   = $("#course_course_end").val()
  let courseTimes = []

  $(".course-time-fields").each(function() {
    let day        = $(this).find('select').find(":selected").val()
    let timeInputs = $(this).find(".time-input")
    if (timeInputs.length > 0) {
      let startTime  = timeInputs[0].value
      let endTime    = timeInputs[1].value
      let courseTime = {
        day: day,
        from_time: startTime,
        end_time: endTime
      }

      courseTimes.push(courseTime)
    }
  })

  $.ajax({
    url: "/search_childcares",
    type: "get",
    dataType: "json",
    data: { 
      course_start: courseStart,
      course_end: courseEnd,
      course_times: courseTimes
    },
    success: function(response) {
      $("#course_childcare_ids").empty()

      $(response).each(function(index, childcare) {
        $("#course_childcare_ids").append(new Option(childcare[0], childcare[1]))
      })
    },
    error: function(xhr) {
      console.log("Error")
    }
  })
}

export default updateChildcareOptions;
