export default class ExamsForm {
  constructor() {
    $(document).ready(function() {
      $("#tab-success-indicator").on("cocoon:after-insert", function(e) {
        flatpickr(".flatpickr-input", {
          "locale": German,
          "dateFormat": "d.m.Y"
        });

        showExamLevel();
      });

      showExamLevels();
    });

    function showExamLevels() {
      $(".person-exam-type").each(function(index, select){
        $(select).on("change", function(){
          showLevelOptions(select);
        })

        showLevelOptions(select);
      });
    }

    function showExamLevel() {
      let select = $(".person-exam-type").last();

      $(select).on("change", function(){
        showLevelOptions(select);
      })

      showLevelOptions(select);
    }

    function showLevelOptions(element) {
      let wrapper           = $(element).parent().parent().parent().parent();
      let selectedLevel     = $("option:selected", element).val();
      let levelOptions      = $(wrapper).find(".exam-type-" + selectedLevel + "-levels");

      resetSubjectLevels(wrapper);

      if (levelOptions.length > 0) {
        let id     = $(levelOptions).find("label").attr("for").split("_")[3];
        let select = $(levelOptions).find("#exam_level");
        $(select).attr("name", "person[exams_attributes][" + id + "][exam_level_id]");
        $(levelOptions).show();
      }
    }

    function resetSubjectLevels(element) {
      $(element).find(".exam-type-levels").hide();
      $(element).find(".exam-type-levels select").each(function(i, select){
        $(select).attr("name", "");
        $(select).prop("required", false);
      })
    }
  }
}
