import DATATABLE_TRANSLATIONS from "./datatable_translations";

export default class ExtLanguageCourseLocationsDataTable {
  constructor() {
    $(document).ready(function () {
      showExtLanguageCourseLocationsTable()
    });

    $(document).on("turbo:render", function () {
      showExtLanguageCourseLocationsTable()
    });

    function showExtLanguageCourseLocationsTable() {
      $('#ext-language-course-location-datatable').dataTable({
        "order": [],
        "columnDefs": [{
          "targets": 'no-sort',
          "orderable": false,
        }],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#ext-language-course-location-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          { "data": "name" },
          { "data": "active" },
          { "data": "actions" }
        ]
        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });
    }
  }
}
