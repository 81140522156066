export default class PersonEducationForm {
  constructor() {
    $(document).ready(function() {
      showDegreeWhere();

      $("#person_education_id").on("change", function() {
        showDegreeWhere();
      });
    });

    function showDegreeWhere() {
      let degreeWhereRequired = $("#person_education_id").find(":selected").data('degree_where_required');

        if (degreeWhereRequired) {
          $(".degree-where-wrapper").find("input").attr('required', true);
          $(".degree-where-wrapper").find("label[for=person_degree_where]").attr('required', true);
          $(".degree-where-wrapper").show();
        } else {
          $(".degree-where-wrapper").find("input").attr('required', false);
          $(".degree-where-wrapper").find("label[for=person_degree_where]").attr('required', false);
          $(".degree-where-wrapper").hide();
        }
    }
  }
}
