import updateChildcareOptions from "./update_childcare_options"

export default class UpdateChildcares {
  constructor() {
    $(document).ready(function() {
      if ($("#course_childcare_ids").length > 0 && $("#course_childcare_ids").val().length < 1) {
        updateChildcareOptions()
      }
    })

    $(".course-datepicker").on("change", function(){
      updateChildcareOptions()
    })

    $(".course-times").on('DOMNodeInserted', function(e) {
      updateChildcareOptionsWhenTimesChanged()
    })

    updateChildcareOptionsWhenTimesChanged()

    function updateChildcareOptionsWhenTimesChanged() {
      $(".course-time-input").on("change", function(){
        updateChildcareOptions()
      })
    }
  }
}
