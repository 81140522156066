export default class SuccessIndicatorsForm {
  constructor() {
    $(document).ready(function() {
      $("#success_indicator_success_indicator").on("change", function() {
        let value = $(this).val()
        showFields(value);
      });

      $("#success_indicator_job_type").on("change", function() {
        let value = $(this).val()
        showInternshipPlace(value);
      });

      $("#success_indicator_passed_on").on("change", function() {
        let value = $(this).val()
        showPassedOnFields(value);
      });

      let value = $("#success_indicator_success_indicator").val()
      showFields(value);

      let jobValue = $("#success_indicator_job_type").val();
      showInternshipPlace(jobValue)

      let passedOnValue = $("#success_indicator_passed_on").val();
      showPassedOnFields(passedOnValue)

      function showInternshipPlace(value) {
        if(value == "Praktikum") {
          $(".internship-field").show();
        } else {
          $(".internship-field").val('').hide();
        }
      }

      function showPassedOnFields(value) {
        if(value == "Anlernberuf") {
          $(".training-job-field").show();
        } else {
          $(".training-job-field").hide().prop('selectedIndex', 0);
        }

        if(value == "Nachbetreuung") {
          $(".post-care-field").show();
        } else {
          $(".post-care-field").hide().prop('selectedIndex', 0);
        }
      }

      function showFields(value) {
        if(value == "Kompetenzcheck") {
          $(".competence-check-fields").show();
        } else {
          $(".competence-check-fields").hide();
          resetSelect($('.competence-check-fields select'));
        }

        if(value == "Beruf") {
          $(".job-fields").show();
        } else {
          $(".job-fields").hide();
          resetSelect($('.job-fields select'));
        }

        if(value == "Weitervermittlung") {
          $(".passed-on-fields").show();
        } else {
          $(".passed-on-fields").hide();
          resetSelect($('.passed-on-fields select'));
        }
      }

      function resetSelect(element) {
        $(element).prop('selectedIndex', 0);
      }
    });
  }
}
