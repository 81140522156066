import i18next from "i18next";
import TRANSLATIONS from "./translations";

i18next.init({
  lng: 'de',
  debug: true,
  resources: TRANSLATIONS
});

export default class PeopleForm {
  constructor() {
    $(document).on("turbo:load", function () {
      showTab();
      modifyUrlByTab();
    });

    $(document).ready(function () {
      showGrantValid();
      showMaritalStatusDiv();
      showResidentAb();
      showResidentNb();
      showResidentStatusDiv();
      showResidentAdditionDiv();
      showUmfTab();
      showBmiDate();
      showBmiFields();
      showAttachedFilename();
      requiredChildrenFields();
      showEducationDescription($("#person_education_id"))

      showTab();
      modifyUrlByTab();

      $("#person_ams").on("change", function () {
        showGrantValid();
      })

      $("#person_marital_status").on("change", function () {
        showMaritalStatusDiv();
      })

      $("#person_resident_status_id").on("change", function () {
        showResidentAb();
        showResidentNb();
        showResidentStatusDiv();
        showResidentAdditionDiv();
      })

      $("#person_resident_ab_id").on("change", function () {
        showResidentAdditionDiv();
      })

      $("#person_resident_nb_id").on("change", function () {
        showResidentAdditionDiv();
      })

      $("#unaccompaniedMinorYes").on("change", function () {
        showUmfTab();
      })

      $("#unaccompaniedMinorNo").on("change", function () {
        showUmfTab();
      })

      $("#integrationAgreementYes").on("change", function () {
        showBmiFields();
      })

      $("#integrationAgreementNo").on("change", function () {
        showBmiFields();
      })

      $("#person_bmi_voucher_date").on("change", function () {
        showBmiDate();
      })

      $("#childcareYes").on("change", function () {
        requiredChildrenFields();
      })

      $("#childcareNo").on("change", function () {
        requiredChildrenFields();
      })

      $("#tab-form-children").on("cocoon:after-insert", function (e) {
        requiredChildrenFields();
      })
    });

    $("#tab-form-attachments").on("cocoon:after-insert", function (e) {
      showAttachedFilename();
    });

    $("#tab-form-certifications").on("cocoon:after-insert", function (e) {
      showAttachedFilename();
    });

    $("#degreeAustria").on("change", function () {
      showDegreeCountry();
    })

    $("#degreeOther").on("change", function () {
      showDegreeCountry();
    })

    $("#person_language_course_before_ifw").on("change", function () {
      showExtCourseData();
    })

    showDegreeCountry();
    showExtCourseData();

    function showEducationDescription(element) {
      if (element) {
        let selectedEducation = $("option:selected", element);
        if (selectedEducation.length > 0 && selectedEducation.val().length > 0) {
          let description = selectedEducation.data('description');
          $("small.education-description").text(description);
        } else {
          $("small.education-description").text('');
        }
      }
    }

    $("#person_education_id").on("change", function () {
      showEducationDescription(this)
    })

    function showDegreeCountry() {
      let other = $("#degreeOther").is(":checked");

      if (other) {
        $(".degree-country").show();
        $(".degree-country > label").attr('required', true)
        $(".degree-country > select").attr('required', true)
      } else {
        $(".degree-country").hide();
        $('#person_degree_country').prop('selectedIndex', 0);
        $(".degree-country > label").attr('required', false)
        $(".degree-country > select").attr('required', false)
      }
    }

    function showExtCourseData() {
      let extCourse = $("#person_language_course_before_ifw").is(":checked");

      if (extCourse) {
        $(".ext-course-data").show();
        $(".ext-course-data > label").attr('required', true)
        $(".ext-course-data > select").attr('required', true)
      } else {
        $(".ext-course-data").hide();
        $(".ext-course-data > label").attr('required', false)
        $(".ext-course-data > select").attr('required', false)
      }
    }

    var ssn_invalid = function (value) {
      var regex = new RegExp('^[0-9]{0,10}$');
      return regex.test(value)
    }

    $.validator.addMethod("validate_ssn", function (value, element) {
      return ssn_invalid(value);
    }, 'SSN must only contains digits');

    $('.person-form').validate({
      ignore: ".ignore",
      rules: {
        "person[ssn]": {
          maxlength: 10,
          validate_ssn: true,
        },
      },
      invalidHandler: function (e, validator) {
        if (validator.errorList.length) {
          let tabId = $(validator.errorList[0].element).closest(".tab-pane").attr('id');
          let tabName = tabId.replace("tab-form", "tab");
          let errorText = i18next.t("error_tab");
          tabName = tabName.split("tab-");
          tabName = tabName[tabName.length - 1].replace("-", "_").toLocaleLowerCase();
          tabName = i18next.t(tabName);
          let alert = `<div class='alert alert-danger' role='alert'>${errorText} ${tabName}</div>`;
          $(".alert").remove();
          $(".alert-container").append(alert).show();
        }
      }
    });

    function showAttachedFilename() {
      $(".custom-file-input").on("change", function () {
        let fileName = $(this).val();
        $(this).next(".custom-file-label").html(fileName);
      })
    }

    function requiredChildrenFields() {
      let requiredInputs = $(".child-fields input").filter(function (index, input) {
        let name = $(input).attr("name");
        if (name) {
          return name.includes("name") || name.includes("birthdate");
        }
      });

      if ($("#childcareYes").is(":checked")) {
        requiredInputs.prop("required", true)
      } else {
        requiredInputs.prop("required", false)
      }

      if ($("#childcareNo").is(":checked")) {
        requiredInputs.prop("required", false)
      } else {
        requiredInputs.prop("required", true)
      }
    }

    function addYearsToDate(date, years) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return day + "." + month + "." + (year + years)
    }

    function showBmiDate() {
      let bmiVoucherDate = $("#person_bmi_voucher_date").val();
      if ($("#person_bmi_voucher_date").length > 0 && bmiVoucherDate !== '') {
        let date = new Date(bmiVoucherDate.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3"));
        let bmiRefundDate = addYearsToDate(date, 2);
        let bmiNoRefundDate = addYearsToDate(date, 5);

        $("#person_bmi_50_refund").val(bmiRefundDate);
        $("#person_bmi_no_refund").val(bmiNoRefundDate);
      }
    }

    function showBmiFields() {
      if ($("#integrationAgreementYes").is(":checked")) {
        $(".bmi-fields").css('display', 'flex');
      } else {
        $(".bmi-fields").hide();
        $(".bmi-fields input").val('');
      }

      if ($("#integrationAgreementNo").is(":checked")) {
        $(".bmi-fields").hide();
        $(".bmi-fields input").val('');
      } else {
        $(".bmi-fields").css('display', 'flex');
      }
    }

    function showUmfTab() {
      let umfYes = $("#unaccompaniedMinorYes").is(":checked");
      let umfNo = $("#unaccompaniedMinorNo").is(":checked");

      if (umfYes || umfNo) {
        umfYes ? $(".umf-form-tab").show() : $(".umf-form-tab").hide();
        umfNo ? $(".umf-form-tab").hide() : $(".umf-form-tab").show();
      }
    }

    function showGrantValid() {
      if ($("#person_ams").is(":checked")) {
        $(".grant_valid_until").show()
      } else {
        $(".grant_valid_until").hide()
      }
    }

    function showMaritalStatusDiv() {
      if ($("#person_marital_status").val() === "sonstiges") {
        $(".marital-status-div").show()
      } else {
        $(".marital-status-div").hide()
        $("#person_marital_status_div").val("");
      }
    }

    function showResidentAb() {
      if ($("#person_resident_status_id option:selected").text() === "Aufenthaltsbewilligung") {
        $(".resident_ab").show()
      } else {
        $(".resident_ab").hide()
        $("#person_resident_ab_id option:selected").prop("selected", false)
      }
    }

    function showResidentNb() {
      if ($("#person_resident_status_id option:selected").text() === "Niederlassungsbewilligung") {
        $(".resident_nb").show()
      } else {
        $(".resident_nb").hide()
        $("#person_resident_nb_id option:selected").prop("selected", false)
      }
    }

    function showResidentStatusDiv() {
      if ($("#person_resident_status_id option:selected").text() === "Sonstige") {
        $(".resident-status-div").show()
      } else {
        $(".resident-status-div").hide()
        $("#person_resident_status_div").val("")
      }
    }

    function showResidentAdditionDiv() {
      let residentStatus = $("#person_resident_status_id option:selected").text();
      let residentAb = $("#person_resident_ab_id option:selected").text();
      let residentNb = $("#person_resident_nb_id option:selected").text();

      if ((residentStatus === "Aufenthaltsbewilligung" && residentAb === "Sonstige") || (residentStatus === "Niederlassungsbewilligung" && residentNb === "Sonstige")) {
        $(".resident-addition-div").show()
      } else {
        $(".resident-addition-div").hide()
        $("#person_resident_addition_div").val("")
      }
    }

    function showTab() {
      let url_string = document.location.href;
      let url = new URL(url_string);
      let tabParams = url.searchParams.get("tab");
      let tab = $(`.nav-tabs a[href='#${tabParams}']`);

      if (tab.length > 0) { tab[0].click(); }
    }

    function modifyUrlByTab() {
      $(".nav-tabs .nav-link").on("click", function (e) {
        let url = window.location.href.split('?')[0];
        let tabName = $(this).attr('href').replace('#', '?tab=');
        window.history.replaceState(null, null, `${url}${tabName}`);
      })
    }
  }
}
