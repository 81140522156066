require('@rails/ujs').start ()
require('jquery')
require('@nathanvda/cocoon')

import '@hotwired/turbo-rails'
import 'controllers'
import './vendor';
import './datatables';

import AddCertification from './components/add_certification';
import AttachmentForm from './components/attachment_form';
import CertificationsForm from './components/certifications_form';
import ChildcareDaysForm from './components/childcare_days_form';
import ConsultationsForm from './components/consultations_form';
import CourseAssignmentsForm from './components/course_assignments_form';
import CoursesForm from './components/courses_form';
import CourseTimes from './components/course_times';
import CustomDatepicker from './components/custom_datepicker';
import CustomTrixEditor from './components/custom_trix_editor';
import ExamsForm from './components/exams_form';
import PeopleForm from './components/people_form';
import PersonCourseForm from './components/person_course_form';
import PersonEducationForm from './components/person_education_form';
import PersonLanguagesForm from './components/person_languages_form';
import PhoneForm from './components/phone_form';
import ProjectForm from './components/project_form';
import ReportForm from './components/report_form';
import SearchCourse from './components/search_course';
import Sidebar from './components/sidebar';
import SuccessIndicatorsForm from './components/success_indicators_form';
import UpdateChildcares from './components/update_childcares';
import UserForm from './components/user_form';

$(function () {
  $.fn.datepicker.dates['de'] = {
    days: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
    daysShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
    daysMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
    months:  ['Januar','Februar','März','April','Mai','Juni', 'Juli','August','September','Oktober','November','Dezember'],
    monthsShort: ['Jan','Feb','Mär','Apr','Mai','Jun', 'Jul','Aug','Sep','Okt','Nov','Dez'],
    format: 'dd.mm.yyyy',
    weekStart: 1
  };

  new AddCertification();
  new AttachmentForm();
  new CertificationsForm();
  new ChildcareDaysForm();
  new ConsultationsForm();
  new CourseAssignmentsForm();
  new CoursesForm();
  new CourseTimes();
  new CustomDatepicker();
  new CustomTrixEditor();
  new ExamsForm();
  new PeopleForm();
  new PersonCourseForm();
  new PersonEducationForm();
  new PersonLanguagesForm();
  new PhoneForm();
  new ProjectForm();
  new ReportForm();
  new SearchCourse();
  new Sidebar();
  new SuccessIndicatorsForm();
  new UpdateChildcares();
  new UserForm();

  $('.dataTables_length select').removeClass('custom-select');
  $('.select-two').select2();
});

$(document).on("turbo:render", function() {
  $('.select-two').select2();
});