import 'moment/locale/de';
import updateChildcareOptions from "./update_childcare_options"
import { German } from "flatpickr/dist/l10n/de.js"

export default class CourseTimesForm {
  constructor() {
    $(document).ready(function() {
      showCourseTimes()

      $(".add-course-time").on("click", function(){
        addCourseTime();
      });

      setCourseTimes();

      $("a.remove-course-time").on("click", function() {
        $(this).parent().parent().remove();
        updateChildcareOptions();
        setCourseTimes();
      })

      $("#course_units").on("change", function(e) {
        e.preventDefault();

        let value = $(this).val();
        $(this).data('default-units', value);

        setCourseTimes();
      })

      $("#course_course_start, #course_course_end").on("change", function() {
        setCourseTimes();
      })

      $(".course-time-input, .additional-holiday-input").on("change", function(){
        setCourseTimes();
      })

      $(".course-times").on('DOMNodeInserted', function(e) {
        e.preventDefault();

        $(e.target).find(".course-time-input").on("change", function(){
          setCourseTimes();
        });
      })

      $(".additional-holidays").on('DOMNodeInserted', function(e) {
        e.preventDefault();

        $(e.target).find(".additional-holiday-input").on("change", function(e){
          updateHolidayHours(e);
          setCourseTimes();
        });
      })

      function updateHolidayHours(e) {
        if ($(e.target).is('select')) {
          let select  = $(e.target);
          let wrapper = select.parent().parent();
          let hours   = select.find(":selected").data('hours');
          let hoursInput = wrapper.find('input[type="number"]');
          hoursInput.attr('min', 0);
          hoursInput.attr('max', hours);
          hoursInput.val(hours);
        }
      }

      $(".add-additional-holiday").on("click", function(e){
        $(".additional-holidays").append($(".hidden-additional-holiday-fields").clone());

        $('.additional-holidays .hidden-additional-holiday-fields').each(function(){
          let inputs                 = $(this).find('select, input');
          let additionalHolidaysSize = $('.additional-holiday-fields').length;

          $(inputs).each(function() {
            var name = $(this).attr('name');
            name     = 'course[additional_holidays][' + additionalHolidaysSize + '][' + name + ']';
            $(this).attr('name', name);
            $(this).prop("required", true);
          })
        })

        updateAdditionalHolidaysOptions();

        $(".additional-holidays .hidden-additional-holiday-fields")
          .removeClass("hidden-additional-holiday-fields")
          .addClass("additional-holiday-fields").show();

        $(".remove-additional-holiday").on("click", function() {
          removeAdditionalHoliday(this);
        })

      });

      $(".remove-additional-holiday").on("click", function() {
        removeAdditionalHoliday(this);
      })

      function removeAdditionalHoliday(element) {
        let wrapper = $(element).parent().parent();
        let value   = wrapper.find('select option:selected').text();
        let currentHoliday = $(".holiday").filter(function(index, row) {
          return $($(row).find('td')[0]).text().includes(value);
        })
        let actualTime = currentHoliday.data('actual-time');
        let fromTime   = currentHoliday.data('from-time');
        let endTime    = currentHoliday.data('end-time');
        let columns    = currentHoliday.find('td');
        $(columns[0]).text(value);
        $(columns[1]).text(actualTime);
        $(columns[2]).text(fromTime);
        $(columns[3]).text(endTime);
        currentHoliday.removeClass('holiday');
        wrapper.remove();
        updateUnitsValue();
      }

      function updateHolidaysUnits() {
        $(".additional-holiday-fields").each(function(index, holiday) {
          let date = $(holiday).find('select option:selected').val();
          let courseDate = $(".course-times-preview-table tbody tr").filter(function(index, row) {
            return $($(row).find('td')[0]).text().includes(date);
          })
          let units = $(courseDate).data('actual-time');
          let input = $(holiday).find('input[type=number]');
          input.attr('min', 0);
          input.attr('max', units);
        })
      }

      function updateAdditionalHolidaysOptions() {
        let courseTimes           = $(".course-times-preview-table").data("courseTimes");
        let additionalCourseTimes = $(".additional-course-time-fields");

        courseTimes = $(courseTimes).filter(function(index, courseTime){
          return courseTime.holidays.length < 1;
        });

        if (additionalCourseTimes && additionalCourseTimes.length > 0) {
          courseTimes = $(courseTimes).filter(function(index, courseTime){
            return courseTime.valid === true;
          })
          courseTimes = formatAdditionalHolidaysOptions(courseTimes);

          $(additionalCourseTimes).each(function(index, additionalCourseTime) {
            let courseTime = formatAdditionalCourseTime(additionalCourseTime)
            courseTimes.push(courseTime);
          })
        } else {
          courseTimes = formatAdditionalHolidaysOptions(courseTimes)
        }

        let select   = $("select.additional-holiday-input");
        let holidays = $(".additional-holiday-fields select.additional-holiday-input");
        $(select).each(function(i, holiday) {
          let value          = $(holiday).val();
          let holidayOptions = [];

          let otherHolidays    = $(holidays).not(holiday);
          let selectedHolidays = $(otherHolidays).find(":selected").map(function(i, option){
            return $(option).val();
          });

          $(holiday).find('option').each(function(i, option) {
            let value = $(option).val();

            if (selectedHolidays.length > 0) {
              if (Array.from(selectedHolidays).includes(value)) {
                option.remove();
              }
            }
          })

          if (value.length > 0) {
            $(holiday).find('option').not(`option[value='${value}']`).not(":eq(0)").remove();
          } else {
            $(holiday).find('option').not(":eq(0)").remove();
          }

          $(courseTimes).each(function(i, courseTime) {
            holidayOptions.push(`<option data-hours=${courseTime.hours} value=${courseTime.date}>${courseTime.day} ${courseTime.date}</option>`);
          });

          $(holiday).append(holidayOptions.join(""));
        });
      }

      function formatAdditionalCourseTime(additionalCourseTime) {
        let inputs = $(additionalCourseTime).find('input');
        let date   = $(inputs[0]).val();
        let day    = convertDay(convertDate(date));
        let fullDate = `${day} ${date}`;
        let courseTime = $("tr.additional").filter(function(index, row) {
          let date = $($(row).find('td')[0]).text();
          return date === fullDate;
        })

        let hours = $($(courseTime).find('td')[1]).text();

        return { day: day, date: date, hours: parseInt(hours) }
      }

      function formatAdditionalHolidaysOptions(courseTimes) {
        return courseTimes.map(function(i, courseTime){
          return { day: courseTime.day, date: courseTime.date, hours: parseInt(courseTime.hours) };
        });
      }

      function setCourseTimes() {
        let startDate          = $("#course_course_start").val();
        let endDate            = $("#course_course_end").val();
        let courseHours        = $("#course_units").val();
        let courseTimes        = [];
        let additionalHolidays = [];

        if (startDate && $(".course-time-fields")) {
          if (startDate.length > 0 && $(".course-time-fields").length > 0) {
            $(".course-time-fields").each(function(i, time){
              let day   = $(time).find('select option:selected').val();
              let times = $(time).find('input');
              let start = $(times[0]).val();
              let end   = $(times[1]).val();
              let courseTime = {
                day: day, from_time: start, end_time: end
              }

              courseTimes.push(courseTime);
            })

            if ($(".additional-holiday-fields").length > 0) {
              $(".additional-holiday-fields").each(function(i, holiday){
                let date  = $(holiday).find('select option:selected').val();
                let notes = $(holiday).find('input[type="text"]').val();
                let hours = $(holiday).find('input[type="number"]').val();
                if (notes === ''){
                  notes = '-'
                };

                let holidayData = { date: date, notes: notes, hours: parseInt(hours) }

                additionalHolidays.push(holidayData);
              })
            }

            if (courseTimes.length > 0) {
              generateCourseTimesPreview(startDate, endDate, courseHours, courseTimes, additionalHolidays);
            }
          }
        }
      }

      function updateUnits(courseTimes) {
        let defaultUnits = $("#course_units").data('default-units');

        if (defaultUnits) {
          $("#course_units").val(defaultUnits);
        } else {
          $("#course_units").val(getValidUnits());
        }
      }

      function addCourseTime() {
        $(".course-times").append($(".hidden-course-time-fields").clone());

        $('.course-times .hidden-course-time-fields').each(function(){
          let inputs          = $(this).find('select, input');
          let courseTimesSize = $('.course-time-fields').length;

          $(inputs).each(function() {
            var name = $(this).attr('name');
            name     = 'course[course_times][' + courseTimesSize + '][' + name + ']';
            $(this).attr('name', name);
            $(this).prop("required", true);
          })
        })

        $(".course-times .hidden-course-time-fields").removeClass("hidden-course-time-fields").addClass("course-time-fields").show();

        $(".course-time-fields").each(function(){
          $(this).find(".remove-course-time").on("click", function() {
            $(this).parent().parent().remove();
            updateChildcareOptions();
            setCourseTimes();
          })
        })
      }

      function showCourseTimes() {
        let dropdown = $(".show-course-times");
        let i;

        for (i = 0; i < dropdown.length; i++) {
          $(dropdown[i]).on("click", function() {
            this.classList.toggle("active");
            let dropdownContent = $(".course-times-table");
            if ($(dropdownContent).is(":visible")) {
              $(dropdownContent).hide();
            } else {
              $(dropdownContent).show();
            }
          });
        }
      }

      function convertDate(date) {
        return new Date(date.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'));
      }

      function convertDay(date) {
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

        return weekday[date.getDay()];
      }

      function generateCourseTimesPreview(startDate, endDate, courseHours, courseTimes, additionalHolidays) {
        let defaultUnits = $("#course_units").data('default-units');
        defaultUnits     = defaultUnits ? true : false

        $.ajax({
          url: "/course_times.json",
          type: "get",
          data: {
            start_date: startDate, end_date: endDate, hours: courseHours,
            course_times: courseTimes, holidays: additionalHolidays,
            show_default_units: defaultUnits
          },
          success: function(response) {
            $(".course-times-preview-table").data("courseTimes", response);
            updateAdditionalHolidaysOptions();
            showCourseTimesPreview(response);
            updateHolidaysUnits();
            updateUnits(response);
          },
          error: function(xhr) {
            $(".course-times-preview-table").hide();
          }
        });
      }

      function showCourseTimesPreview(courseTimes) {
        $(".course-times-preview-table tbody tr").remove();
        $(".course-times-preview-table").show();

        $(courseTimes).each(function(i, courseTime) {
          let holidays = "";
          let hours    = courseTime.hours;
          if (courseTime.holidays.length > 0) {
            holidays = courseTime.holidays.map(function(holiday, index) { return holiday.name }).join('');
            holidays = `(${holidays}) `
            hours    = courseTime.holidays.map(function(holiday, index) { return parseInt(holiday.hours) }).reduce(function(a, b) {
              return a + b;
            });
          }
          let timeLeft   = parseInt(courseTime.hours);
          let actualTime = courseTime.actual_time;
          let className  = [];

          if (holidays.length > 0) {
            className.push("holiday");
          } else {
            if (timeLeft !== 0 && timeLeft < actualTime) {
              className.push("invalid-course-time");
            }
          }
          className = className.join(" ");

          $(".course-times-preview-table tbody").append(
            `<tr data-actual-time=${actualTime} data-from-time=${courseTime.from_time} data-end-time=${courseTime.end_time} class=${className}>` +
            `<td>${holidays}${courseTime.day} ${courseTime.date}</td>` +
            `<td>${holidays.length > 0 ? hours ? actualTime - hours : 0 : hours}</td>` +
            `<td>${holidays.length > 0 ? '-' : courseTime.from_time == null ? '-' : courseTime.from_time}</td>` +
            `<td>${holidays.length > 0 ? '-' : courseTime.end_time == null ? '-' : courseTime.end_time}</td>` +
            "</tr>"
          );
        })

        showAdditionalCourseTimes();
      }

      // Add additional course time

      $(".add-additional-course-time").on("click", function() {
        addAdditionalCourseTimeForm();
        showAdditionalCourseTimes();
        removeAdditionalCourseTime();
      })

      removeAdditionalCourseTime();

      function removeAdditionalCourseTime() {
        $(".remove-additional-course-time").on("click", function(e) {
          e.preventDefault();

          let currentAdditionalCourseTime = $(this).parent().parent();
          let date = $(currentAdditionalCourseTime).find('input')[0].value;

          $(currentAdditionalCourseTime).remove();

          let courseTime = $("tr.additional").filter(function(index, row){
            let value = $($(row).find('td')[0]).text().split(' ')[1]
            return date === value;
          });

          $(courseTime).remove();
          showAdditionalCourseTimes();

          let lastCourseTime = $(".course-times-preview-table tbody tr").last();
          if (lastCourseTime.hasClass("invalid-course-time") || lastCourseTime.hasClass("additional-invalid")) {
            lastCourseTime.show();
            $(".required-additional-course-time").show();
          } else {
            lastCourseTime.hide();
            $(".required-additional-course-time").hide();
          }

          updateUnitsValue();
        })
      }

      function addAdditionalCourseTimeForm() {
        $(".additional-course-times").append($(".hidden-additional-course-time-fields").clone());

        $('.additional-course-times .hidden-additional-course-time-fields').each(function(){
          let inputs          = $(this).find('select, input');
          let courseTimesSize = $('.additional-course-time-fields').length;
      
          $(inputs).each(function() {
            var name = $(this).attr('name');
            name     = 'course[additional_course_time][' + courseTimesSize + '][' + name + ']';
            $(this).attr('name', name);
            $(this).prop("required", true);
          })
        })

        $(".additional-course-times .hidden-additional-course-time-fields")
          .removeClass("hidden-additional-course-time-fields")
          .addClass("additional-course-time-fields").show();

        formatDatePicker();

        $(".flatpickr-input").on("change", function() {
          formatDatePicker();
        })
      }

      function formatDatePicker() {
        flatpickr(".flatpickr-input", {
          "locale": German,
          "dateFormat": "d.m.Y"
        });
      }

      function showAdditionalCourseTimes() {
        $(".additional-course-time").on("change", function() {
          let wrapper = $(this).parent().parent();
          addAdditionalCourseTimes(wrapper);
        })

        $(".additional-course-time-fields").each(function (index, additionalCourseTime) {
          let wrapper = $(additionalCourseTime);
          addAdditionalCourseTimes(wrapper);
        });
      }

      function updateCourseEnd() {
        let courseDates    = getCurrentCourseDates();
        let courseEnd      = $("#course_course_end").val();
        let lastCourseDate = courseDates[courseDates.length - 1];
        if (convertDate(lastCourseDate) > convertDate(courseEnd)) {
          $("#course_course_end").val(lastCourseDate);
        }
      }

      function addAdditionalCourseTimes(wrapper) {
        let inputs   = $(wrapper).find('input');
        let date     = $(inputs[0]).val();
        let day      = convertDay(convertDate(date));
        let fullDate = `${day} ${date}`;
        let fromTime = $(inputs[1]).val();
        let endTime  = $(inputs[2]).val();
        let hours    = convertHours(convertTime(fromTime), convertTime(endTime));

        $(inputs).each(function() {
          $(this).prop("required", true);
        })

        if (date.length > 0 && fromTime.length > 0 && endTime.length > 0) {
          if (getDates().includes(date)) {
            wrapper.find('.invalid-additional-course-time').show();
          } else {
            wrapper.find('.invalid-additional-course-time').hide();
            appendAdditionalCourseTime(fullDate, hours, fromTime, endTime);
          }
          updateCourseEnd();
        }

        updateUnitsValue();
      }

      function updateUnitsValue() {
        $("#course_units").val(getValidUnits());
      }

      function appendAdditionalCourseTime(fullDate, hours, fromTime, endTime) {
        let sameDate = $('.additional').filter(function(index, additional) {
          return $($(additional).find('td')[0]).text() === fullDate;
        })

        if (sameDate.length > 0) {
          rewriteAdditionalCOurseTime(sameDate, fullDate, hours, fromTime, endTime);
          var currentAdditionalCourseTime = $(sameDate);
        } else {
          $(".course-times-preview-table tbody").append(
            `<tr data-actual-time=${hours} data-from-time=${fromTime} data-end-time=${endTime} class=additional>` +
            `<td>${fullDate}</td>` +
            `<td>${hours}</td>` +
            `<td>${fromTime == null ? '-' : fromTime}</td>` +
            `<td>${endTime == null || endTime == undefined ? '-' : endTime}</td>` +
            "</tr>"
          );

          var currentAdditionalCourseTime = $(".additional").last();
        }

        $('.invalid-course-time').hide();

        if (!currentAdditionalCourseTime.hasClass('additional')) {
          if (getValidUnits() >= getUnits()) {
            currentAdditionalCourseTime.prev().removeClass('additional-invalid')
            currentAdditionalCourseTime.removeClass('additional-invalid');
            $(".required-additional-course-time").hide();
          } else {
            currentAdditionalCourseTime.prev().removeClass('additional-invalid')
            currentAdditionalCourseTime.addClass('additional-invalid');
            $(".required-additional-course-time").show();
          }
        }
      }

      function rewriteAdditionalCOurseTime(element, fullDate, hours, fromTime, endTime) {
        $(element).html(
          `<td>${fullDate}</td>` +
          `<td>${hours}</td>` +
          `<td>${fromTime == null ? '-' : fromTime}</td>` +
          `<td>${endTime == null || endTime == undefined ? '-' : endTime}</td>`
        )
      }

      function getValidUnits() {
        let units = $(".course-times-preview-table tbody tr:not(.invalid-course-time, .holiday)").map(function(index, row){
          let unit = $(row).data('actual-time');
          unit     = unit ? unit : 0;
          return unit;
        });

        let holidays = $(".course-times-preview-table tbody .holiday").map(function(index, row){
          let unit = $($(row).find('td')[1]).text();
          unit     = unit.length > 0 ? parseInt(unit) : 0;
          return unit;
        });

        units    = Array.from(units).map(function(unit, index) { return convertHoursToDecimal(unit) } );
        holidays = Array.from(holidays).map(function(unit, index) { return convertHoursToDecimal(unit) } );
        units    = sumArray(Array.from(units));
        holidays = sumArray(Array.from(holidays));

        return units + holidays;
      }

      function convertHoursToDecimal(hours) {
        if (typeof(hours) === 'string') {
          hours = hours.replace(',', '.')
        }
        return parseFloat(hours);
      }

      function sumArray(array) {
        return array.length > 0 ? array.reduce((a, b) => a + b) : 0;
      }

      function getUnits() {
        return parseInt($("#course_units").val());
      }

      function getDates() {
        let dates = $(".course-times-preview-table tbody tr:not(.additional)").map(function(index, row){
          return $($(row).find('td')[0]).text().split(' ')[1];
        });
        return Array.from(dates);
      }

      function getCurrentCourseDates() {
        let dates = $(".course-times-preview-table tbody tr").map(function(index, row){
          let columns = $(row).find('td');
          let date    = $(columns[0]).text().split(' ')[1]; 
          return date;
        });

        dates = Array.from(dates);
        dates = dates.sort(function(a,b) {
          return convertDate(a) - convertDate(b);
        });
        return dates;
      }

      function convertTime(time) {
        let now = new Date();
        let nowDateTime = now.toISOString();
        let nowDate = nowDateTime.split('T')[0];
        return new Date(`${nowDate} ${time}`);
      }

      function convertHours(fromTime, endTime) {
        let hours = (endTime - fromTime) / 3600000;
        hours = Math.round(hours * 10) / 10;
        hours = hours % 1 > 0.5 ? Math.round(hours) : Math.round(hours * 2.0) / 2.0;
        return hours;
      }
    })
  }
}
