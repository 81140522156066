export default class ConsultationsForm {
  constructor() {
    $(document).ready(function() {
      $("#consultation_consultation_type_id").on("change", function() {
        resetSubjectLevels();
        showSubTypeOptions();
        showSubTypeOther();
      });

      showSubTypeOptions();
      showSubTypeOther();

      function showSubTypeOptions(element) {
        let selectedLevel = $("option:selected", element).val();
        let levelOptions  = $(".consultation-type-" + selectedLevel + "-sub-types");
  
        if (levelOptions.length > 0) {
          let select = $(levelOptions).find("select");
          $(select).attr("name", "consultation[consultation_sub_type_id]");
          $(select).attr("id", "consultation_consultation_sub_type_id");
          $(levelOptions).show();
        }
      }
  
      function resetSubjectLevels() {
        $(".consultation-type-sub-types").hide();
        $(".consultation-type-sub-types select").each(function(i, select){
          $(select).attr("name", "");
          $(select).val('');
        })
      }

      function showSubTypeOther() {
        $("#consultation_consultation_sub_type_id").each(function(i, select) {
          $(select).on("change", function(){
            setSubTypeOther(this);
          });

          setSubTypeOther(select);
        });
      }

      function setSubTypeOther(element) {
        if($("option:selected", element).val() === "sonstiges") {
          if($("option:selected", element).val() === "" && $("#consultation_consultation_subtype_other").attr('value').length > 0) {
            $(element).val('sonstiges');
          }
          showSubTypeOtherInput();
        } else {
          hideSubTypeOtherInput();
        }
      }

      function showSubTypeOtherInput() {
        $(".consultation-subtype-other-field").show();
        $("#consultation_consultation_subtype_other").prev('label').attr("required", "required");
        $("#consultation_consultation_subtype_other").prop("required", true);
      }

      function hideSubTypeOtherInput() {
        $(".consultation-subtype-other-field").hide();
          $("#consultation_consultation_subtype_other").val("");
          $("#consultation_consultation_subtype_other").prop("required", false);
          $("#consultation_consultation_subtype_other").prev('label').attr("required", null);
      }
    });
  }
}
