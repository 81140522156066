export default class ChildcareDaysForm {
  constructor() {
    $(document).ready(function() {
      $(".add-day").on("click", function(){
        $(".days").append($(".hidden-day-fields").clone());

        $('.days .hidden-day-fields').each(function(){
          let inputs   = $(this).find('select, input');
          let daysSize = $('.day-fields').length;

          $(inputs).each(function() {
            var name = $(this).attr('name');
            name     = 'childcare[days][' + daysSize + '][' + name + ']';
            $(this).attr('name', name);
            $(this).prop("required", true);
          })
        })

        $(".days .hidden-day-fields").removeClass("hidden-day-fields").addClass("day-fields").show();

        $(".day-fields").each(function(){
          $(this).find(".remove-day").on("click", function() {
            $(this).parent().parent().remove();
          })
        })
      });

      $("a.remove-day").on("click", function() {
        $(this).parent().parent().remove();
      })
    })
  }
}
