export default class CertificationsForm {
  constructor() {
    $(document).ready(function() {
      $("#tab-form-certifications").on("cocoon:after-insert", function(e) {
        showSubjectLevel();
      });

      showSubjectLevels();
    });

    function showSubjectLevels() {
      $(".person-cert-subject").each(function(index, select){
        let wrapper         = $(select).parent().parent().parent();
        let selectedSubject = $("option:selected", select).text().toLowerCase().replace(" ", "-");
        let levelOptions    = $(wrapper).find(".subject-" + selectedSubject + "-levels");
        let level           = $(levelOptions).data("level").replace( /_space_/g, ' ' );

        $(select).on("change", function(){
          showLevelOptions(select);
        })

        showLevelOptions(select);
        $("select", levelOptions).val(level);
        $("select", levelOptions).prop("required", true);
      });
    }

    function showSubjectLevel() {
      let select = $(".person-cert-subject").last();
      let inputs = $(select).parent().parent().parent().parent().find("input:visible, select:visible");

      $(select).on("change", function(){
        showLevelOptions(select);
        requiredCertificationFields(inputs);
      })

      requiredCertificationFields(inputs);
      showLevelOptions(select);
    }

    function requiredCertificationFields(inputs) {
      $(inputs).each(function(index, input){
        if(input.name.includes("certification_exam")) {
          $(input).prop("required", false);
        } else {
          $(input).prop("required", true);
        }
      })
    }

    function showLevelOptions(element) {
      let wrapper         = $(element).parent().parent().parent();
      let selectedSubject = $("option:selected", element).text().toLowerCase().replace(" ", "-");
      let levelOptions    = $(wrapper).find(".subject-" + selectedSubject + "-levels");

      resetSubjectLevels(wrapper);

      if (levelOptions.length > 0) {
        let id     = $(levelOptions).find("label").attr("for").split("_")[3];
        let select = $(levelOptions).find("#certification_level");

        $(select).attr("name", "person[certifications_attributes][" + id + "][certification_level]");
        $(levelOptions).show()
      }
    }

    function resetSubjectLevels(element) {
      $(element).find(".subject-levels").hide();
      $(element).find(".subject-levels select option:selected").prop("selected", false);
      $(element).find(".subject-levels select").each(function(i, select){
        $(select).attr("name", "");
        $(select).prop("required", false);
      })
    }
  }
}
