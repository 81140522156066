export default class CourseAssignmentsForm {
  constructor() {
    $(document).ready(function() {
      var assignments = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
          url: '/course_assignments.json',
          filter: function(list) {
            return $.map(list, function(assignment) {
              return { name: assignment }; });
          }
        }
      });

      assignments.clearPrefetchCache();
      assignments.initialize();

      $('.course-assignments input').tagsinput({
        typeaheadjs: {
          name: 'assignments',
          displayKey: 'name',
          valueKey: 'name',
          source: assignments.ttAdapter()
        }
      });
    });
  }
}
