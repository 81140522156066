export default class Sidebar {
  constructor() {
    $(document).ready(function () {
      showSidebar();
      if (Cookies.get("collapsedSidebar") == "yes") {
        collapseSidebar();
      } else {
        expandSidebar();
      }
    });

    $(document).on("turbolinks:load", function () {
      showSidebar();
      if (Cookies.get("collapsedSidebar") == "yes") {
        collapseSidebar();
      } else {
        expandSidebar();
      }
    });

    $(document).on("turbo:render", function () {
      showSidebar();
      if (Cookies.get("collapsedSidebar") == "yes") {
        collapseSidebar();
      } else {
        expandSidebar();
      }
    });

    $(document).on("turbo:load", function () {
      showSidebar();
    });

    $(document).on("click", ".nav-item.nav-collapse-sidebar", function () {
      if ($(".sidebar.sidebar-icon-only").length > 0) {
        expandSidebar();
        Cookies.remove("collapsedSidebar");
      } else {
        collapseSidebar();
        Cookies.set("collapsedSidebar", "yes", { expires: 365 });
      }
    });

    function expandSidebar() {
      $(".sidebar").removeClass("sidebar-icon-only");
      $(".nav-link .fa.fa-caret-down").removeClass("d-none");
      $('main[role="main"]').addClass("ml-sm-auto col-md-10 col-lg-10");
      $('main[role="main"]').removeClass("col-md-11 col-lg-11");
      $("body").removeClass("bg-secondary");
      $(".nav-item span.text-link").removeClass("d-none");
      $(".nav-item.nav-collapse-sidebar .fa").addClass("fa-chevron-left");
      $(".nav-item.nav-collapse-sidebar .fa").removeClass("fa-chevron-right");
    }

    function collapseSidebar() {
      $(".sidebar").addClass("sidebar-icon-only");
      $(".nav-link .fa.fa-caret-down").addClass("d-none");
      $('main[role="main"]').removeClass("ml-sm-auto col-md-10 col-lg-10");
      $('main[role="main"]').addClass("col-md-11 col-lg-11");
      $("body").addClass("bg-secondary");
      $(".nav-item span.text-link").addClass("d-none");
      $(".nav-item.nav-collapse-sidebar .fa").removeClass("fa-chevron-left");
      $(".nav-item.nav-collapse-sidebar .fa").addClass("fa-chevron-right");
    }

    function showSidebar() {
      let dropdown = $(".parent-menu");
      let i;

      for (i = 0; i < dropdown.length; i++) {
        $(dropdown[i]).on("click", function () {
          this.classList.toggle("active");
          let dropdownContent = this.nextElementSibling;
          if (dropdownContent.style.display === "block") {
            $(dropdownContent).hide();
          } else {
            $(dropdownContent).show();
          }
        });
      }

      let menus = [
        "users",
        "subjects",
        "occupations",
        "capabilities",
        "providers",
        "institutions",
        "organizations",
        "resident_statuses",
        "resident_abs",
        "resident_nbs",
        "school_visits",
        "projects",
        "target_audiences",
        "course_levels",
        "relationships",
        "exam_types",
        "exam_levels",
        "training_jobs",
        "post_cares",
        "competence_check_places",
        "consultation_types",
        "consultation_sub_types",
        "holidays",
        "caregivers",
        "consultation_subjects",
        "repetition_reasons",
        "course_end_reasons",
        "reports",
        "educations",
      ];

      $(menus).each(function (index, menu) {
        if (window.location.href.includes(menu)) {
          $(".dropdown-container").show();
        }
      });
    }
  }
}
