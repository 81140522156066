function updateProjectOptions() {
  let courseLevelOptions    = $("#course_level_id option");
  let targetAudienceOptions = $("#target_audience_id option");

  if ($("#project_id").length > 0 && $("#project_id").val().length > 0) {
    let selectedProject   = $("#project_id option:selected");
    let courseLevelIds    = selectedProject.data("course-level-ids");
    let targetAudienceIds = selectedProject.data("targe-audience-ids");

    courseLevelIds    = courseLevelIds.length > 1 ? courseLevelIds.split(',') : [courseLevelIds.toString()];
    targetAudienceIds = targetAudienceIds.length > 1 ? targetAudienceIds.split(',') : [targetAudienceIds.toString()];

    courseLevelOptions.hide();
    targetAudienceOptions.hide();

    $(courseLevelOptions).each(function(index, option) {
      if (courseLevelIds.includes(option.value)) {
        $(option).show();
      } else {
        $(option).hide();
      }
    })

    $(targetAudienceOptions).each(function(index, option) {
      if (targetAudienceIds.includes(option.value)) {
        $(option).show();
      } else {
        $(option).hide();
      }
    })
  } else {
    courseLevelOptions.show();
    targetAudienceOptions.show();
  }
}

export default updateProjectOptions;
