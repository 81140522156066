import DATATABLE_TRANSLATIONS from "./datatable_translations";

export default class UsersDataTable {
  constructor() {
    $(document).ready(function() {
      showUsersTable();
    });

    $(document).on("turbo:render", function() {
      showUsersTable();
    });

    function showUsersTable() {
      $('#users-datatable').dataTable({
        "order": [],
        "columnDefs": [
          { "orderable": true, "targets": [0] },
          { "orderable": false, "targets": [1, 2] },
        ],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#users-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "username"},
          {"data": "roles"},
          {"data": "actions"}
        ]
        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });
    }
  }
}
