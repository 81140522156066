import DATATABLE_TRANSLATIONS from "./datatable_translations";

export default class PeopleAssignmentDataTable {
  constructor() {
    $(document).ready(function() {
      showPeopleAssignmentTable();
    });

    $(document).on("turbo:render", function() {
      showPeopleAssignmentTable();
    });

    function showPeopleAssignmentTable() {
      let peopleAssignmentTable = $('#people-assignment-datatable').DataTable({
        "columnDefs": [
          {
            "searchable": false,
            "orderable": false,
            "targets": 0,
          },
        ],
        "order": [[1, 'asc']],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#people-assignment-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "counter" },
          {"data": "first_name"},
          {"data": "last_name"},
          {"data": "ssn"},
          {"data": "course_assignments"},
          {"data": "actions"}
        ]
        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });

      showCounter(peopleAssignmentTable);

      let activeAssignmentTable = $('#active-assigned-people-datatable').DataTable({
        "pageLength": 50,
        "columnDefs": [
          {
            "searchable": false,
            "orderable": false,
            "targets": 0,
          },
        ],
        "order": [[1, 'asc']],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#active-assigned-people-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "counter" },
          {"data": "first_name"},
          {"data": "last_name"},
          {"data": "ssn"},
          {"data": "course_assignments"},
          {"data": "course_canceled"},
          {"data": "course_changed"},
          {"data": "actions"}
        ],
        "fnInitComplete": function (oSettings, json) {
          showAddCourseEndingButton();
        },
        "fnDrawCallback": function( oSettings ) {
          showAddCourseEndingButton();
        }

        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });

      showCounter(activeAssignmentTable);

      let inactiveAssignmentTable = $('#inactive-assigned-people-datatable').DataTable({
        "pageLength": 50,
        "columnDefs": [
          {
            "searchable": false,
            "orderable": false,
            "targets": 0,
          },
        ],
        "order": [[1, 'asc']],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#inactive-assigned-people-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "counter" },
          {"data": "first_name"},
          {"data": "last_name"},
          {"data": "ssn"},
          {"data": "course_assignments"},
          {"data": "course_canceled"},
          {"data": "course_changed"},
          {"data": "actions"}
        ],
        "fnInitComplete": function (oSettings, json) {
          showAddCourseEndingButton();
        },
        "fnDrawCallback": function( oSettings ) {
          showAddCourseEndingButton();
        }

        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });

      showCounter(inactiveAssignmentTable);
    }

    function showCounter(table) {
      table.on('order.dt search.dt', function () {
        let i = 1;

        table.cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
          this.data(i++);
        });
      }).draw();
    }

    function showAddCourseEndingButton() {
      $(".mark_course_end").each(function() {
        $(this).on("change", function() {
          updatePrintPdfUrl();
          checkPersonCourses();
        });
      })

      $(".check-all-people").on("click", function() {
        updatePrintPdfUrl();
        checkPersonCourses();
      })
    }

    function updatePrintPdfUrl() {
      let personCourseIds = $(".mark_course_end:checked").map(function(i, personCourse){
        return $(personCourse).val();
      });
  
      personCourseIds = Array.from(personCourseIds);
      if (personCourseIds.length > 0) {
        $(".print-all-pdf").map(function(i, link) {
          let url = link.href;
          url = url.split('person_courses');
          let urlFirst = url[0];
          let slug = $(link).data('slug');
          link.href = `${urlFirst}person_courses/${personCourseIds.join(',')}/print_multiple_reports.pdf?slug=${slug}`;
        });
      }
    }

    function checkPersonCourses() {
      let personCourseIds = $('.mark_course_end:checked').map(function() {
        return $(this).val();
      }).get().join(",");

      $("#sms_person_course_ids").val(personCourseIds);
      $("#bulk_update_person_course_ids").val(personCourseIds);

      if ($('.mark_course_end:checked').length > 0) {
        $(".add-course-endings").show();
        $(".edit-course-starts").show();
        $(".send-sms").show();
        $(".print-pdf").css('display', 'contents');
      } else {
        $(".add-course-endings").hide();
        $(".edit-course-starts").hide();
        $(".add-course-endings-wrapper").hide();
        $(".print-pdf").hide();
        $(".send-sms").hide();
        $(".send-sms-wrapper").hide();
        $(".uncheck-all-people").hide();
        $(".check-all-people").show();
      }

      if (personCourseIds.length > 0) {
        let personCoursesCount = personCourseIds.split(',').length;
        $(".selected-assigned-people-info").show();
        $("#selected-assigned-people").text(personCoursesCount);
      } else {
        $(".selected-assigned-people-info").hide();
      }
    }
  }
}
