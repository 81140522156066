export default class PhoneForm {
  constructor() {
    $(document).ready(function() {
      $(".project-logo-input").on("change", function(e) {
        e.preventDefault();
        let input = this;
        if (input.files && input.files[0]) {
          let reader = new FileReader();

          reader.onload = function (e) {
            let image = $(input).parent().parent().find('.project-logo img');
            $(image).attr('src', e.target.result)
          };

          reader.readAsDataURL(input.files[0]);
        }
      })
    });
  }
}
