export default class AttachmentForm {
  constructor() {
    $(document).ready(function() {
      $(".remove-document").on("click", function(event){
        let documentId = $(this).data('document-id');

        $.ajax({
          type: "DELETE",
          url: "/remove_document/" + documentId,
          success() {
            $("#removeDocument" + documentId + " .close").click();
            $(".alert-container").append("<div class='alert alert-success' role='alert'>Document has been removed!</div");
            $("#document" + documentId).remove();
            $("#removeDocument" + documentId).remove();
          },
          error() {
            $("#removeDocument" + documentId + " .close").click();
            $(".alert-container").append("<div class='alert alert-danger' role='alert'>Document failed to removed!</div");
          }
        })
      })
    })
  }
}