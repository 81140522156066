import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import updateProjectOptions from "../packs/components/update_project_options";

// Connects to data-controller="course"
export default class extends Controller {
  selectProject() {
    updateProjectOptions();
  }
}
