export default class AddCertification {
  constructor() {
    $(document).ready(function() {
      $('#add-certification-level').on('click', function(){
        $('.cert-fields').append($('.hidden-cert-field').clone());
        $('.cert-fields .hidden-cert-field').removeClass('hidden-cert-field').addClass('cert-field').show();

        $('.cert-field').each(function(){
          $(this).find('.delete-certification-level').on('click', function() {
            $(this).parent().parent().parent().parent().remove();
          })
        })
      });

      $('a.delete-certification-level').on('click', function() {
        $(this).parent().parent().remove();
      })
    })
  }
}