export default class UserForm {
  constructor() {
    $(document).ready(function() {
      if ($(".edit_user")) {
        $("input[name='user[role_ids][]']").on("change", function() {
          showProjects();
        })

        showProjects();
      }
    });

    function showProjects() {
      let checkedRoles = $("input[name='user[role_ids][]']:checked");
      checkedRoles = $(checkedRoles).map(function(index, role) {
        return $.trim($(role).next().text());
      });
      checkedRoles = Array.from(checkedRoles);
      let projectLabel = $(".user-projects").find('label');

      if (checkedRoles.includes('Consultant')) {
        $(".user-projects").show();
        $(projectLabel).attr('required', true);
        $("#user_project_ids").attr('required', true);
      } else {
        $(".user-projects").hide();
        $(projectLabel).attr('required', false);
        $("#user_project_ids").attr('required', false);
        $("#user_project_ids").val('').change();
      }
    }
  }
}
