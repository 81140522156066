const TRANSLATIONS = {
  en: {
    translation: {
      "attachments": "Attachments",
      "certifications": "Certifications",
      "children": "Children",
      "consultations": "Consultations",
      "contact": "Contact",
      "courses": "Courses",
      "error_tab": "There is an error on tab",
      "goals": "Goals",
      "main": "Main",
      "organizations": "Organizations",
      "other": "Other",
      "school": "School",
      "state": "State",
      "success_indicator": "Success Indicator",
      "umf": "UMF",
      "work": "Work"
    }
  },
  de: {
    translation: {
      "attachments": "Anhänge",
      "certifications": "Einstufungen",
      "children": "Kinder",
      "consultations": "Beratungen",
      "contact": "Kontakt",
      "courses": "Kurse",
      "error_tab": "Es gibt einen Fehler im Reiter: ",
      "goals": "Zielvereinbarungen",
      "main": "Hauptdaten",
      "organizations": "Organisationen",
      "other": "Sonstiges",
      "school": "Schule",
      "state": "Status",
      "success_indicator": "Erfolgsindikator",
      "umf": "UMF",
      "work": "Beschäftigung"
    }
  }
}

export default TRANSLATIONS;
