export default class PersonLanguagesForm {
  constructor() {
    $(document).ready(function() {
      $("#tab-form-state").on("cocoon:after-insert", function(e) {
        disablePrimaryChecks();
      });

      $(".primary-check").each(function(i, input) {
        $(input).on("change", function(){
          disablePrimaryChecks();    
        })
      })

      disablePrimaryChecks();

      function disablePrimaryChecks() {
        let primaryLanguage = $(".person-languages-fields input[type='checkbox']:checked");
        if (primaryLanguage.length > 0) {
          $(".person-languages-fields input[type='checkbox']:not(:checked)").prop("disabled", "disabled");
        } else {
          $(".person-languages-fields input[type='checkbox']:not(:checked)").prop("disabled", null);
        }
      }
    });
  }
}
