import DATATABLE_TRANSLATIONS from "./datatable_translations";

export default class DashboardProvidersDataTable {
  constructor() {
    $(document).ready(function() {
      showDashboardProvidersTable();
    });

    $(document).on("turbo:render", function() {
      showDashboardProvidersTable();
    });

    function showDashboardProvidersTable() {
      $('#dashboard-providers-datatable').dataTable({
        "order": [[0, 'asc']],
        "columnDefs": [
          {
            "targets": [0],
            "visible": true,
            "orderData": [0]
          },
          {
            "targets": [1],
            "orderable": false,
            "orderData": [1]
          },
          {
            "targets": [2],
            "orderable": false,
            "orderData": [2]
          }
        ],
        "language": DATATABLE_TRANSLATIONS,
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#dashboard-providers-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "name"},
          {"data": "people_with_gdpr_issues"},
          {"data": "actions"}
        ]
        // pagingType is optional, if you want full pagination controls.
        // Check dataTables documentation to learn more about
        // available options.
      });
    }
  }
}
