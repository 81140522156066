import AttachmentsDataTable from '../components/attachments_data_table';
import CapabilitiesDataTable from '../components/capabilities_data_table';
import CaregiversDataTable from '../components/caregivers_data_table';
import ChildcaresDataTable from '../components/childcares_data_table';
import ChildcareCoursesDataTable from '../components/childcare_courses_data_table';
import ChildrenDataTable from '../components/children_data_table';
import CompetenceCheckPlacesDataTable from '../components/competence_check_places_data_table';
import ConsultationsDataTable from '../components/consultations_data_table';
import ConsultationSubTypesDataTable from '../components/consultation_sub_types_data_table';
import ConsultationSubjectsDataTable from '../components/consultation_subjects_data_table';
import ConsultationTypesDataTable from '../components/consultation_types_data_table';
import CourseEndReasonsDataTable from '../components/course_end_reasons_data_table';
import CourseLevelsDataTable from '../components/course_levels_data_table';
import CoursesDataTable from '../components/courses_data_table';
import DashboardProvidersDataTable from '../components/dashboard_providers_data_table';
import EducationssDataTable from '../components/educations_data_table';
import ExamLevelsDataTable from '../components/exam_levels_data_table';
import ExamTypesDataTable from '../components/exam_types_data_table';
import ExtLanguageCourseLocationsDataTable from '../components/ext_language_course_locations_data_table';
import ExtLanguageCourseTimesDataTable from '../components/ext_language_course_times_data_table';
import ExtLanguageCourseDurationsDataTable from '../components/ext_language_course_durations_data_table';
import ExtLanguageCourseLevelsDataTable from '../components/ext_language_course_levels_data_table';
import ExtLanguageCourseExamsDataTable from '../components/ext_language_course_exams_data_table';
import ExtLanguageCourseReasonsDataTable from '../components/ext_language_course_reasons_data_table';
import ExtLanguageCourseRecommendationsDataTable from '../components/ext_language_course_recommendations_data_table';
import GoalsDataTable from '../components/goals_data_table';
import GroupConsultationsDataTable from '../components/group_consultations_data_table';
import HolidaysDataTable from '../components/holidays_data_table';
import InstitutionsDataTable from '../components/institutions_data_table';
import OccupationsDataTable from '../components/occupations_data_table';
import OrganizationsDataTable from '../components/organizations_data_table';
import PeopleAssignmentDataTable from '../components/people_assignment_data_table';
import PeopleDataTable from '../components/people_data_table';
import PersonAttachmentsDataTable from '../components/person_attachments_data_table';
import PersonChildrenDataTable from '../components/person_children_data_table';
import PersonCoursesDataTable from '../components/person_courses_data_table';
import PostCaresDataTable from '../components/post_cares_data_table';
import ProjectsDataTable from '../components/projects_data_table';
import ProvidersDataTable from '../components/providers_data_table';
import RelationshipsDataTable from '../components/relationships_data_table';
import RepetitionReasonsDataTable from '../components/repetition_reasons_data_table';
import ReportsDataTable from '../components/reports_data_table';
import ResidentAbsDataTable from '../components/resident_abs_data_table';
import ResidentNbsDataTable from '../components/resident_nbs_data_table';
import ResidentStatusesDataTable from '../components/resident_statuses_data_table';
import SchoolVisitsDataTable from '../components/school_visits_data_table';
import SubjectsDataTable from '../components/subjects_data_table';
import TargetAudiencesDataTable from '../components/target_audiences_data_table';
import TrainingJobsDataTable from '../components/training_jobs_data_table';
import UsersDataTable from '../components/users_data_table';

$(function () {
  new AttachmentsDataTable();
  new CapabilitiesDataTable();
  new CaregiversDataTable();
  new ChildcaresDataTable();
  new ChildcareCoursesDataTable();
  new ChildrenDataTable();
  new CompetenceCheckPlacesDataTable();
  new ConsultationsDataTable();
  new ConsultationSubTypesDataTable();
  new ConsultationSubjectsDataTable();
  new ConsultationTypesDataTable();
  new CourseEndReasonsDataTable();
  new CourseLevelsDataTable();
  new CoursesDataTable();
  new DashboardProvidersDataTable();
  new EducationssDataTable();
  new ExamLevelsDataTable();
  new ExamTypesDataTable();
  new ExtLanguageCourseLocationsDataTable();
  new ExtLanguageCourseTimesDataTable();
  new ExtLanguageCourseDurationsDataTable();
  new ExtLanguageCourseLevelsDataTable();
  new ExtLanguageCourseExamsDataTable();
  new ExtLanguageCourseReasonsDataTable();
  new ExtLanguageCourseRecommendationsDataTable();
  new GoalsDataTable();
  new GroupConsultationsDataTable();
  new HolidaysDataTable();
  new InstitutionsDataTable();
  new OccupationsDataTable();
  new OrganizationsDataTable();
  new PeopleAssignmentDataTable();
  new PeopleDataTable();
  new PersonAttachmentsDataTable();
  new PersonChildrenDataTable();
  new PersonCoursesDataTable();
  new PostCaresDataTable();
  new ProjectsDataTable();
  new ProvidersDataTable();
  new RelationshipsDataTable();
  new RepetitionReasonsDataTable();
  new ReportsDataTable();
  new ResidentAbsDataTable();
  new ResidentNbsDataTable();
  new ResidentStatusesDataTable();
  new SchoolVisitsDataTable();
  new SubjectsDataTable();
  new TargetAudiencesDataTable();
  new TrainingJobsDataTable();
  new UsersDataTable();
});
