export default class PersonCourseForm {
  constructor() {
    $(document).ready(function() {
      $(".hide-change-person-course").on("click", function(e) {
        e.preventDefault();

        $(".change-person-course-wrapper").hide();
      });

      $(".hide-cancel-person-course").on("click", function(e) {
        e.preventDefault();

        $(".cancel-person-course-wrapper").hide();
      });

      $(".hide-add-course-ending").on("click", function(e) {
        e.preventDefault();

        $(".add-course-ending-wrapper").hide();
      });

      $(".hide-add-course-repetition").on("click", function(e) {
        e.preventDefault();

        $(".add-course-repetition-wrapper").hide();
      });

      $("#person_course_course_cancel_reason_id").on("change", function() {
        showOtherReason($(this));
      });

      showOtherReason($("#person_course_course_cancel_reason_id"));
    });

    function showOtherReason(input) {
      let value            = $(input).val();
      let otherReasonInput = $("#person_course_course_cancel_reason_other");

      if (value == 'other') {
        $(".cancel-reason-other-wrapper").show();
        $(otherReasonInput).prop("required", true)
      } else {
        $(otherReasonInput).val('');
        $(".cancel-reason-other-wrapper").hide();
        $(otherReasonInput).prop("required", false)
      }
    }
  }
}
